import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import ExternalLink from "../components/blog/ExternalLink";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return  <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Grocery Shopping Tips We All Need In Our Life"
            description="Grocery shopping can come with a lot of challenges. Here are battle-tested grocery shopping tips that will greatly improve your experience."
            location={location}
            pageType="article"
        />
        <HeroSection>
            <BlogTitle>
                Grocery Shopping Tips We All Need In Our Life
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Grocery-Shopping-Tips-We-All-Need-In-Our-Life.jpg" alt="Grocery Shopping Tips We All Need In Our Life"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@norevisions?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">No Revisions</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            The thing about grocery shopping is that you think it is easy. You go into the store, get the things you need, pay for them, and go home.
        </Paragraph>
        <Paragraph>
            But then you realize you forgot to buy something. Or maybe that particular grocery shop doesn't have all the items you need. Or your grocery bill came out unreasonably high and you don’t know why. A couple of days pass and you’re already out of milk? How is that possible?
        </Paragraph>
        <Paragraph>
            You start digging deeper and it becomes obvious that grocery shopping and grocery planning are not as simple as they look. Sure, it’s not rocket science, but winning or failing at it can make a world of difference in your daily life.
        </Paragraph>
        <Paragraph>
            So here’s what you should know and pay attention to before your next shopping trip. We’ve compiled a comprehensive list of grocery shopping tips that will forever change the way you approach this task - for the better, of course.
        </Paragraph>
        <BlogHeading2>
            The best time to go grocery shopping
        </BlogHeading2>
        <Paragraph>
            First and foremost, just like you may want to pick the best grocery store for your needs, it is equally important to choose the ideal time of day (and week) for your grocery run.
        </Paragraph>
        <Paragraph>
            <InternalLink to="/when-is-the-best-time-to-go-grocery-shopping/">When is the best time to go grocery shopping?</InternalLink>
        </Paragraph>
        <Paragraph>
            The truth is that different regions of the world have different customs and regulations when it comes to work hours and the busiest times in a supermarket.
        </Paragraph>
        <Paragraph>
            Through our research, however, we’ve found that three time periods may be most suitable for you no matter where you’re located geographically:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                <b>Late at night:</b> If you have a store that is open past 9 PM (ideally one that is open 24/7), late at night is your prime shopping hour. The aisles will be deserted, the store will be quiet, and you’ll nab the perfect parking spot without any trouble.
            </UnorderedListItem>
            <UnorderedListItem>
                <b>Super early in the morning:</b> By ‘super early,’ we mean before 9 AM or, even better, before 8 AM. Of course, this is also reserved only for the stores that are open so early in the morning. If you want to make sure there is as little of a crowd as possible, be the first through the gate the moment the store opens and you should be good.
            </UnorderedListItem>
            <UnorderedListItem>
                <b>Midday during the week:</b> Whether they work a 9 to 5 job or in shifts, most people are too busy in the middle of a weekday to go grocery shopping. Which is exactly why this time is one of our top three grocery shopping hours. Aim for between noon and 2 PM for that sweet spot.
            </UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Times to avoid
        </BlogHeading3>
        <Paragraph>
            If there are ideal times to go shopping, it stands to reason that there are also moments when you should reconsider your idea of a grocery trip.
        </Paragraph>
        <Paragraph>
            Here are two timeframes you should avoid at all costs if you want a fast and stress-free shopping experience:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                <b>The weekend:</b> The <ExternalLink to="https://slidelegend.com/grocery-shopping-time-use-institute_59ea37411723dde1b7d8ecfb.html">Time Use Institute</ExternalLink> named Saturday as the worst day of the week to go grocery shopping. We are going a step further and advising you to stay away from the supermarkets at any point from Friday mid-afternoon to Monday morning, if at all possible. The late at night and super early tips from above may work here, but there is no guarantee that you will come out the other side in one piece.
            </UnorderedListItem>
            <UnorderedListItem>
                <b>Immediately after work:</b> How many times did you decide to get some things on your way home from the office? How many times did you regret that decision halfway through the process? Most stores become packed between 4 and 7 PM for this exact reason - working people thinking it would be convenient to shop before they get home from work. Chances are, you’ll save a lot more time if you simply go shopping that same day after dinner.
            </UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Organize your shopping list like a pro
        </BlogHeading2>
        <Paragraph>
            After deciding when you’re going to shop, next up is figuring out what you’re going to buy.
        </Paragraph>
        <Paragraph>
            Fact of the matter is, you cannot - and should not - go to a store without a shopping list. If you have to buy more than three things, there’s a high chance that you will forget at least one.
        </Paragraph>
        <Paragraph>
            This is backed up by <ExternalLink to="https://journals.sagepub.com/doi/10.1509/jmr.12.0065">scientific research</ExternalLink> that says that, on average, consumers forget to buy 30% of the items they intend to buy.
        </Paragraph>
        <Paragraph>
            A grocery shopping list doesn't only help with not having to go back to the store for things you forgot, but it also helps lower your grocery bill and prevent impulse purchases. Not to mention that it is an indispensable tool if you want to have a well-balanced diet.
        </Paragraph>
        <Paragraph>
            Let’s take a look at essential tips on how to organize your grocery shopping list.
        </Paragraph>
        <BlogHeading3>
            Keep a running list
        </BlogHeading3>
        <Paragraph>
            One of the best pieces of advice we can offer is to keep a running list. For example, if you’re shopping every Thursday night, you’re going to start a grocery list on Friday and keep adding to it until Thursday shopping time comes. Then rinse and repeat.
        </Paragraph>
        <Paragraph>
            That way you’ll be sure you haven’t forgotten anything.
        </Paragraph>
        <Paragraph>
            One option to make this happen includes having a notepad somewhere in the house to quickly jot down what you notice or remember you need to get.
        </Paragraph>
        <Paragraph>
            Or, alternatively, you can download a shopping list app that you’ll always have with you, no matter what. (It’s not just us that take our phones everywhere, right?)
        </Paragraph>
        <BlockQuote>
            As it so happens, our <InternalLink to="/#download-section">free grocery shopping list app</InternalLink> will be perfect just for that!
        </BlockQuote>
        <BlogHeading3>
            Make a meal plan
        </BlogHeading3>
        <Paragraph>
            Meal planning is not crucial for successful grocery shopping. But it is useful.
        </Paragraph>
        <Paragraph>
            By planning out your meals for the week - or even only some of your meals - you’ll know exactly how much of which ingredients you need and what you’re going to cook at what time. And if you want to be mindful about your diet, you have the advantage of planning it all out in advance to make sure you’re getting all the necessary nutrients to fuel your body.
        </Paragraph>
        <Paragraph>
            If it seems overwhelming to plan out an entire week of meals, start by simply listing some of your favorite breakfasts or dinners. Make your favorite pancakes on Thursday or lasagna on Sunday or avocado on toast on Tuesday. By planning out and scheduling even only a few meals a week, you’ll already have a clearer picture of what ingredients you’ll need to make them.
        </Paragraph>
        <Paragraph>
            Of course, don’t forget to add those ingredients to your shopping list!
        </Paragraph>
        <Paragraph>
            Check out our <InternalLink to="/how-to-organize-your-grocery-shopping-list/">free, printable table for seven-day meal planning</InternalLink> in this blog post.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/Lined-up-containers-filled-with-colorful-prepared-meals.jpg" alt="Lined up containers filled with colorful prepared meals"/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@swell?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">S'well</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            Organize list by aisle
        </BlogHeading3>
        <Paragraph>
            Once you have a list of things you need to buy, it wouldn't hurt to organize them in some way.
        </Paragraph>
        <Paragraph>
            The most common method for organizing your shopping list is to sort every item according to its corresponding aisle in the store.
        </Paragraph>
        <Paragraph>
            For example, milk belongs in dairy; chicken breast is where other meats and seafood are, and frozen veggies are in the frozen foods section.
        </Paragraph>
        <Paragraph>
            Fortunately, most stores categorize their items in a similar way, so even if you are going to a shop for the very first time, your aisle-organized list should still shave precious minutes off your shopping time.
        </Paragraph>
        <Paragraph>
            Here are the major categories you should keep in mind when sorting your list items:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                Fresh produce
            </UnorderedListItem>
            <UnorderedListItem>
                Meat and seafood
            </UnorderedListItem>
            <UnorderedListItem>
                Deli
            </UnorderedListItem>
            <UnorderedListItem>
                Dairy
            </UnorderedListItem>
            <UnorderedListItem>
                Bakery
            </UnorderedListItem>
            <UnorderedListItem>
                Frozen goods section
            </UnorderedListItem>
            <UnorderedListItem>
                Pet food
            </UnorderedListItem>
            <UnorderedListItem>
                Health products
            </UnorderedListItem>
            <UnorderedListItem>
                Beauty products
            </UnorderedListItem>
            <UnorderedListItem>
                Household goods
            </UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Organize list by price
        </BlogHeading3>
        <Paragraph>
            Another option for maximizing your efficiency is to sort the items you want to buy according to their price. This is especially useful if you’re on a budget.
        </Paragraph>
        <Paragraph>
            You can move the least expensive items to the top of the list. The top spots can also be reserved for the items you can buy in bulk as well as those that you can use in many different ways and in many recipes, such as eggs, butter, onions and garlic, canned vegetable options, etc.
        </Paragraph>
        <BlockQuote>
            Psst, <InternalLink to="/#download-section">Going Shopping</InternalLink> also offers you the option to enter the price of each food item you want to buy and know the total before you get to the store!
        </BlockQuote>
        <BlogHeading2>
            Save money while shopping
        </BlogHeading2>
        <Paragraph>
            In these uncertain times when inflation is rising and the <ExternalLink to="https://www.bls.gov/opub/ted/2022/food-prices-up-10-8-percent-for-year-ended-april-2022-largest-12-month-increase-since-november-1980.htm#:~:text=SUBSCRIBE-,Food%20prices%20up%2010.8%20percent%20for%20year%20ended%20April%202022,month%20increase%20since%20November%201980&text=For%20the%20year%20ended%20April,Urban%20Consumers%20increased%208.3%20percent.">cost of pretty much everything is skyrocketing</ExternalLink>, more and more people (us included) want to know how they can save money while shopping.
        </Paragraph>
        <Paragraph>
            Let’s now explore the top five tried and true <InternalLink to="/how-to-save-money-grocery-shopping/">tips on how to save money grocery shopping</InternalLink>. Hopefully, they will help you get through this rough patch the global economy has found itself in.
        </Paragraph>
        <BlogHeading3>
            1. Watch for sales
        </BlogHeading3>
        <Paragraph>
            It may sound tedious, but it is really useful to be up to date with all the current and upcoming sales in your local grocery stores. Browse through the store websites or mobile apps and skim through their leaflets for sale offers. There are bound to be new ones every week.
        </Paragraph>
        <Paragraph>
            Of course, this isn’t an invitation to buy an item just because it is on sale. Rather, decide to buy an item on sale only if you truly need it and/or it is something you frequently use. If it’s something you can stock up on, maybe buy more than just for one week when the price is reduced.
        </Paragraph>
        <BlogHeading3>
            2. Buy in bulk
        </BlogHeading3>
        <Paragraph>
            Speaking of stocking up, if you’re feeding a large family, buying in bulk always pays off. Wholesale stores such as <ExternalLink to="https://www.costco.com/">Costco</ExternalLink> offer large quantities of items at lower prices for anyone who has the money up front and the storage space to keep them.
        </Paragraph>
        <Paragraph>
            Buying in bulk may not be an option for everyone, but it is something to consider for saving money in the future.
        </Paragraph>
        <BlogHeading3>
            3. Sign up for loyalty programs and clip coupons
        </BlogHeading3>
        <Paragraph>
            Most, if not all, grocery stores offer some kind of a loyalty program, which is a reward for customers who frequently shop there. With a loyalty card, you may get discounts on special items or accumulate points to reduce your next grocery bill or a similar perk which should make a lot of difference in the long run.
        </Paragraph>
        <Paragraph>
            Additionally, coupon clipping is another great way to pay less on your next grocery run. Find coupons in the morning papers, in printed store offers, and maybe even online. Don’t hesitate to take them with you next time you go shopping.
        </Paragraph>
        <BlogHeading3>
            4. Look for generic store brands
        </BlogHeading3>
        <Paragraph>
            Just like most stores have a loyalty program, most big supermarket chains have their own generic store brands of the most common items people buy. These generic brands are lower in price than the brands you’re used to (research suggests <ExternalLink to="https://www.consumerreports.org/cro/magazine/2012/10/store-brand-vs-name-brand-taste-off/index.htm">up to 25% lower</ExternalLink>!) and can prove as a great alternative if you want to meet your grocery budget.
        </Paragraph>
        <Paragraph>
            Next time you go shopping, look for the store brand versions of what you want to buy, and check how big the price difference is. A lower price does not have to mean lower quality, especially for items such as flour, powdered sugar, baking mixes, brown sugar, teas, etc. Why not try out the generic brand and see what happens?
        </Paragraph>
        <BlogHeading3>
            5. Avoid pre-packaged items
        </BlogHeading3>
        <Paragraph>
            Convenience is expensive. Any convenient item, such as pre-chopped fresh fruit, pre-washed salad greens or pre-grated cheese, costs more than its unprepared counterpart. If you want to go easy on your wallet, stay away from these pre-packaged items and instead choose the whole, un-chopped, un-grated, and unpeeled products.
        </Paragraph>
        <Paragraph>
            They may require more time to prepare to eat at home, but at least your bank balance will be grateful.
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            Grocery shopping does not have to be complex or stressful. By following all the tips and advice we outlined above, you can navigate this important part of daily life without much trouble.
        </Paragraph>
        <Paragraph>
            If you want specialized additional tips, such as <InternalLink to="/how-to-go-grocery-shopping-with-a-baby/">how to go grocery shopping with a baby</InternalLink>, you can check out our article on that.
        </Paragraph>
        <Paragraph>
            The important thing to remember is that you can do this. Anyone can learn anything, including how to be more efficient, organized, productive, and time-saving, especially in regards to grocery shopping.
        </Paragraph>
        <Paragraph>
            And if you need a little extra help in the process, don’t forget to <InternalLink to="/#download-section">download Going Shopping</InternalLink>, our free shopping list app, that will help you never again forget anything at the store!
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}

export default BlogPost;